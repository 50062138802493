<template>
  <div class="InMail">
    <div v-loading="loading"></div>
    <div class="InMails flex">
      <div class="InMailList" v-infinite-scroll="load">
        <div class="InMail-Info flex2">
          <div>
            <img v-if="Infos.pictureUrl" :src="Infos.pictureUrl" class="Info-img">
            <img v-else src="@/assets/PC/shop/shop-InfoHead.jpg" class="Info-img">
          </div>
          <div style="width: 100%; overflow: hidden">
            <h2 class="co-fff hidden">{{Infos.name}}</h2>
          </div>
        </div>
        <div class="InMail-search">
          <el-input v-model="msgs.title" @keyup.enter.native="Search" class="InMail-search-inp" placeholder="搜索通知"></el-input>
          <i class="el-icon-search cur" @click="Search"></i>
        </div>
        <div v-if="!Isdata" class="InMail-msg-list">
          <div v-for="item in msgList.data" :key="item.id" class="InMail-msg-item flex cur" @click="goInMallDetail(item.id)" :class="DetailId == item.id ? 'InMail-msg-item-active':''">
            <div class="InMail-msg-item-icon flex1">
              <img src="@/assets/PC/home/znx.png">
            </div>
            <div class="InMail-msg-item-d"> 
              <div class="flex3" style="width: 100%">
                <span v-if="item.type == 0" class=" co-fff">系统通知</span>
                <span class="InMail-msg-item-deta">{{ item.dateTimeString }}</span>
              </div>
              <div class="flex3" style="width: 100%">
                <span class="InMail-msg-item-title">{{ item.introduction }}</span>
                <div v-if="!item.isReaded" class="box-msg"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="Isdata" style="height: 90px" class="flex1 co-7E3">暂无消息</div>
      </div>
      <div v-if="clientWidth > 750" class="InMailList-d">
        <div v-if="IsDetail" class="InMailList-d-s">
          <div class="InMailList-d-s-title">系统通知</div>
          <div class="InMailList-d-s-deta co-999 tc">{{InMallDetail.dataTimeString}}</div>
          <div class="InMailList-d-s-content flex">
            <div class="InMailList-d-s-content-icon flex1">
              <img src="@/assets/PC/home/znx.png">
            </div>
            <div class="InMailList-d-s-content-text">
              <b>亲爱的{{ Info.name }}，你好：</b> 
              <p v-html="InMallDetail.content"></p> 
              <div class="san0"></div>
            </div>
          </div>
        </div>
        <div v-else class="co-999 flex1" style="height: 100%">您还未选中通知，点击左侧查看通知</div>
      </div>
    </div>
  </div>  
</template>

<script>
import { GetSystemMessage, GetSystemMessageInfo, GetUserInfo, GetSystemMessageTop4} from "@/api/profile.js";
import { debounce } from "@/utils/common";

export default {
  name: "InMail",
  data() {
    return {
      loading: true,                          // !loading
      loadings: true,
      Info: this.$store.state.Infos,          // !个人信息
      msgs:{
        page: 1,
        limit: 10,
        title: '',
      },
      msgList: {data: [], recordCount: 0 },   // !消息列表
      InMallDetail: {},                       // !详情数据
      IsDetail: false,                        // !是否展开详情
      DetailId: '',                           // !当前选中id
      Isdata: false,                          // !暂无消息
      IsloadList: false,                      // !下拉加载
    }
  },
  mounted() {
    if(!this.$store.state.token) {
      this.$router.push('/');
    }else {
      if(sessionStorage.getItem('msgs')){
        this.msgs = JSON.parse(sessionStorage.getItem('msgs'));
      }
      this.GetInit();
    }
  },
  methods: {
    // !初始化列表
    GetInit(isTrue) {
      GetSystemMessage(this.msgs).then(res => {
        if(res.status == 1) {
          if(isTrue) {
            this.msgList.data = res.data;
            isTrue = false;
          }else {
            this.msgList.data =this.msgList.data.concat(res.data);
          }
          
          if(this.msgList.data.length == res.recordCount) {
            this.IsloadList = false;
          } else {
            this.IsloadList = true;
          }
          if(this.msgList.data.length > 0) {
            this.Isdata = false;
          }else {
            this.Isdata = true;
          }
        }
      }).then(()=> {
        if(this.$route.query.id) {
          if(this.clientWidth > 750) {
            this.DetailId = this.$route.query.id;
            this.GetInitId(this.DetailId)
          }
        }
      })
      this.loading = false;
    },
    load() {
      debounce(()=> {
        if(this.IsloadList) {
        this.msgs.page++;
        this.GetInit();
      }
      },1000)
      
    },
    // !搜索
    Search() {
      this.msgs.page = 1;
      this.GetInit(true);
    },
    // !查看消息详情
    goInMallDetail(id) {
      let iIndex = '';
      this.msgList.data.filter( (v, i) => {
        if(v.id == id) {
          iIndex = i;
        }
        return iIndex;
      })
      this.msgList.data[iIndex].isReaded = true;

      if(this.clientWidth > 750) {
        this.$router.push('/Home/InMail?id=' + id);
      } else if(this.clientWidth < 751){
        this.$router.push('/Home/InMailDetail?id=' + id);
      }
    },
    // !获取详情
    GetInitId(id) {
      this.DetailId = id;
      const Id = {messageId: id};
      GetSystemMessageInfo(Id).then(res => {
        if(res.status == 1) {
          this.InMallDetail = res.data;
          this.IsDetail = true;
        } else {
          this.$toast(res.message);
        }
      }).then(()=> {
        GetUserInfo().then(res => {// !获取个人信息
          this.$store.dispatch('Profile', res.data);
        });
        GetSystemMessageTop4().then(res => {
          if(res.status == 1) {
            this.$store.dispatch('MsgTop4', res.data);
          }
        });

        this.msgList.data.filter((v, i) => {
          if(v.id == this.$route.query.id){
            this.msgList.data[i].isReaded = true;
          }
          return this.msgList.data;
        })
      })
    }
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
    Infos() {
      return this.$store.state.Infos;
    },
  },
  watch: {
    $route: {
      handler(val) {
        if(val.query.id) {
          this.GetInitId(val.query.id);
        }
      },
      deep: true,
    },
    Infos(val) {
      this.Info = val;
    },
  }
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .InMail {
    .InMail-msg-item-d {width: 100%;}
    .InMails { width: 1200px; height: 500px; background: #fff; box-shadow: 0 0 15px #ccc; margin: 40px auto; border-radius: 5px; overflow: hidden;}
    .InMailList { width: 333px; height: 500px; background: #3D1152; overflow: auto;}

    .InMailList-d { width: 100%;}
    .InMailList-d-s { padding: 40px; height: 100%;}
    .InMailList-d-s-title { font-size: 18px; padding-bottom: 20px; border-bottom: 1px #7E3D9D solid; margin-bottom: 40px;}
    .InMailList-d-s-deta { font-size: 12px; margin-bottom: 21px;}
    .InMailList-d-s-content-text { padding: 15px; background: #F2F5F7; margin-left: 30px; border-radius: 5px; position: relative;}
    .san0 {width: 0;height: 0;border-top: 7px solid transparent;border-right: 15px solid #F2F5F7;border-bottom: 7px solid transparent; position: absolute; left: -14px; top: 15px;}
    .InMailList-d-s-content-text b {font-size: 18px; }
    .InMailList-d-s-content-text p { margin-top: 5px; }

    .InMail-Info, .InMail-search {padding: 16px; position: relative;}
    .InMail-search, .InMail-msg-item-active { background: #340d49;}
    .InMail-search i {color: #A872C1;position: absolute; left: 30px; top: 23px;}
    .Info-img { width: 62.4px; height: 62.4px; border-radius: 31.2px; margin-right: 8px;}

    .InMail-msg-item {padding: 13px 16px;border-bottom: 1px rgb(36, 35, 35) solid;}
    .InMail-msg-item-icon, .InMailList-d-s-content-icon {min-width: 43px; width: 43px; height: 43px; background: #F2F5F7; border-radius: 50%; margin-right: 10px;}
    .InMail-msg-item-icon img, .InMailList-d-s-content-icon img {width: 20px;}

    .InMail-msg-item-deta, .InMail-msg-item-title, .co-7E3 {color: #7E3D9D;}
  }

  @media (max-width: 750px) {
    .InMail {
      .InMails, .InMailList{width: 100%;}
      .InMailList {height: calc( 100vh - 13.3333vw);}
      .InMails {margin: 0;height: calc( 100vh - 13.3333vw); border-radius: 0;}

      .InMail-Info {padding: vw(40);}
      .InMail-search {padding: vw(16) vw(40);}
      .Info-img {width: vw(120); height: vw(120);}
      .InMail-search i {font-size: vw(29); left: vw(80); top: vw(39);}

      .InMail-msg-item {padding: vw(40);}
      .InMail-msg-item-icon {width: vw(64); min-width: vw(64); height: vw(64);}
      .InMail-msg-item-icon img {width: vw(37);}

      .InMail-msg-item-title {font-size: vw(24); margin-top: vw(5);}
    }
  }
</style>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .InMailList::-webkit-scrollbar {width: 5px;}
  .InMailList::-webkit-scrollbar-track {-webkit-border-radius: 2em;-moz-border-radius: 2em;border-radius:2em}
  .InMailList::-webkit-scrollbar-thumb {background-color:#4d1965;-webkit-border-radius: 2em;-moz-border-radius: 2em;border-radius:2em}
  @media (min-width: 751px) {
    .InMail-search-inp input {height: 28px; border-radius: 14px; background: #4d1965; border: 0; padding-left: 40px; color: #fff;}
  }
  @media (max-width: 750px) {
    .InMail-search-inp input {height: vw(72); border-radius: vw(36); background: #4d1965; border: 0; padding-left: 40px;  color: #fff;}
  }
</style>